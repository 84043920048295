<template>
  <page :styleWrap='{background: "#fff"}' :footer='80' :state='pageStatus'>
    <div class="chooseGroupPage">
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div>
        <div class="markPanel underline">
          <img class="icon_build buildIcon" src="../../assets/icon_mark.png" alt="">
          <input type="text" class="markInput" v-model='groupName' placeholder="请输入群名称">
        </div>

        <div class="f_flex f_a_c" style="margin-bottom: 16px;">
          <img class="icon_build" src="../../assets/icon_building.png" alt="">
          <div class="build_text">选择公司</div>
        </div>

        <div class='radioPanel'>
          <van-radio-group v-model="company">
            <van-radio
              v-for='(item, index) in companyInfoList' 
              :key='index' 
              class="radioItem"
              :name="item.companyId">
              {{item.companyName}}
            </van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>

    <div slot="footer" style="padding-top: 8px">
      <button class="btn btn_radius theme_bg margin_auto confirmBtn" @click='goNextPage'>下一步</button>
    </div>
  </page>
</template>

<script>
import { RadioGroup, Radio } from 'vant';
import userApi from '@/api/user'
import {mapActions} from 'vuex'

export default {
  data: ()=> ({
    company: null,
    groupName: '',
    companyInfoList: [],
    pageStatus: 'loading'
  }),
  components: {
    'van-radio-group': RadioGroup,
    'van-radio': Radio,
  },
  methods: {
    ...mapActions([
      'set_group_company'
    ]),
    getCompanyItem(res, id) {
      const item = res.find(item => item.id === id)
      return item
    },
    goNextPage() {
      if (!this.groupName) {
        this.$toast('群名不能为空')
        return
      }
      if (this.company) {
        const list = this.companyInfoList
        const companyItem = list.find(item => item.id === this.company)
        this.set_group_company(companyItem)
        this.$router.push(`/build/chooseConactM?companyId=${this.company}&groupName=${this.groupName}&from=create`)
      } else {
        this.$toast('请选择公司')
      }
    },
    getCompanyList() {
      userApi.queryCompanyInfoList({}).then(res => {
        if (res.data) {
          this.companyInfoList = res.data.companyInfoList
          this.pageStatus = 'success'
        } else {
          this.pageStatus = 'fail'
        }
      })
    }
  },
  mounted() {
    // const userInfo = this.$global.getItem('userInfo')
    // if (userInfo && userInfo.companyInfoList) {
    //   this.companyInfoList = userInfo.companyInfoList
    // }
    this.getCompanyList()
  }
}
</script>

<style lang="less" scoped>
.chooseGroupPage {
  position: relative;
  padding: 0 16px;
}
.markPanel {
  position: relative;
  padding: 15px 0;
  margin-bottom: 14px;
  .buildIcon {
    position: absolute;
  }
  .markInput {
    font-size: 16px;
    border: none;
    outline: none;
    background: transparent;
    margin-left: 16px;
    padding-left: 14px;
  }
}
.radioPanel {
  padding-left: 31px;
}
.radioItem {
  margin-bottom: 21px;
}
.icon_build {
  width: 16px;
  height: 20px;
  margin-right: 12px;
}
.build_text {
  font-size: 16px;
  color: #2D2D2D;
}
.confirmBtn {
  width: 86%;
}
</style>